import React, { useEffect } from 'react'
import useAuth from '@hooks/useAuth'
import isBrowser from '@utils/isBrowser'
import { navigate } from 'gatsby'

function Logout () {
  const { accessToken, signOut } = useAuth()

  useEffect(() => {
    const bootstrapAsync = (): void => {
      if (isBrowser()) {
        if (accessToken)
          signOut()
        else
          navigate('/login', { replace: true })
      }
    }

    bootstrapAsync()
  }, [])

  return null
}

export default Logout
